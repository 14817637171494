import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import QRCodeScanner from './QRCodeScanner';

const theme = createTheme({
	palette: {
		primary: {
			main: '#00A1DE'
		},
		secondary: {
			main: '#005bbb'
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				body {
					min-height: 100vh;
					border: 25px solid #00A1DE;
				},
				body.success {
					min-height: 100vh;
					border: 25px solid green;
				},
				body.warning {
					min-height: 100vh;
					border: 25px solid orange;
				},
				body.danger {
					min-height: 100vh;
					border: 25px solid red;
				}`,
		}
	}
});

function ScannerApp( props ) {
	const [ticketNumber, setTicketNumber] = useState( 0 );
	const [showTicket, setShowTicket] = useState( false );
	const [ticket, setTicket] = useState( { } );

	const [attendeeName, setAttendeeName] = useState( '' );
	const [showAttendees, setShowAttendees] = useState( false );
	const [tickets, setTickets] = useState( [ ] );

	const [checkins, setCheckins] = useState( { } );

	const handleBadTicket = ( ) => {
		document.body.classList.add( 'danger' );
		setTimeout( function( ) { document.body.classList.remove( 'danger' ); setTicketNumber( 0 ); }, 5000 );
	}

	const handleTicketScan = ( ticket_number ) => {
		setTicketNumber( ticket_number );
	}

	const handleChangeAttribute = ( event ) => {
		event.preventDefault( );

		const key = event.target.name,
			value = event.target.value;

		setTicket( ticket => { 
			ticket.attributes[key] = value;
			return { ...ticket }; 
		} );
	};

	const handleLookupTicketSubmit = (event) => {
		event.preventDefault( );

		const data = new FormData( event.currentTarget );
		setTicketNumber( data.get( 'ticketNumber' ) );
	};

	const handleLookupAttendeeSubmit = (event) => {
		event.preventDefault( );

		const data = new FormData( event.currentTarget );
		setAttendeeName( data.get( 'attendeeName' ) );
	};

	const handleCheckin = id => e => {
		const { checked } = e.target;
		setCheckins( ( values ) => ( {
			...values,
			[ id ]: checked
		} ) );
	};

	const handleTicketCheckinSubmit = (event) => {
		event.preventDefault( );

		fetch( 
			window.__rest_uri+'/v2/tickets/'+ticketNumber, 
			{ 
				method: 'PUT',
				body: JSON.stringify( { 
					attributes: JSON.stringify( ticket.attributes )
				} ),
				headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) }
			} 
		)
		.then( ( data ) => data.json( ) )
		.then( function( data ) {
			document.body.classList.remove( 'warning' );
			document.body.classList.remove( 'success' );

			setShowTicket( false );
			setTicketNumber( 0 );
			setTicket( { } );
		} )
		.catch( ( error ) => { console.error( error ); } );
	};

	const handleMultipleTicketCheckinSubmit = (event) => {
		event.preventDefault( );

		Object.keys(checkins).forEach( key => {
			if ( true === checkins[key] )
			{
				fetch(
					window.__rest_uri+'/v2/tickets/'+key, 
					{ 
						method: 'PUT',
						headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) }
					} 
				)
				.then( ( data ) => data.json( ) )
				.catch( ( error ) => { console.error( error ); } );
			}
		} );

		setShowAttendees( false );
		setAttendeeName( '' );
		setTickets( [ ] );
		setCheckins( { } );
	};

	useEffect( ( ) => {
		const paths = window.location.pathname.split( '/' );
		if ( 2 <= paths.length && 0 === paths[1].indexOf( 'tk_' ) && 3 < paths[1].length )
			setTicketNumber( parseInt( paths[1].replace( 'tk_', '' ) ) );
	}, [ ] );

	useEffect( ( ) => {
		if ( 0 < ticketNumber )
		{
			const scanner = JSON.parse( localStorage.getItem( 'scanner' ) );

			fetch( 
				window.__rest_uri+'/v2/tickets/'+ticketNumber, 
				{ headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
			)
			.then( ( data ) => data.json( ) )
			.then( function( data ) {
				if ( data.hasOwnProperty( 'ExceptionName' ) && -1 !== data.ExceptionName.indexOf( 'NoAuthenticatedSessionPresent' ) )
				{
					props.handleNoAuth( );
					return;
				}

				if ( 'object' !== typeof data || !data.hasOwnProperty( 'attributes' ) )
				{
					handleBadTicket( );
					return;
				}

				if ( null !== data.attributes && 0 < data.attributes.length )
				{
					const maybe_attributes = JSON.parse( data.attributes );
					if ( 'object' === typeof maybe_attributes )
						data.attributes = maybe_attributes;
					else
						data.attributes = { };
				}
				else
					data.attributes = { };

				if ( null === scanner || !scanner.hasOwnProperty( 'event_id' ) || scanner.event_id !== data.event_id )
				{
					handleBadTicket( );
					return;
				}

				if ( scanner.hasOwnProperty( 'ticket_id' ) && null !== scanner.ticket_id && scanner.ticket_id !== data.ticket_id )
				{
					handleBadTicket( );
					return;
				}

				if ( scanner.hasOwnProperty( 'room_id' ) && null !== scanner.room_id && scanner.room_id !== data.tickettype.room_id )
				{
					handleBadTicket( );
					return;
				}

				setTicket( data );

				if ( null !== data.scanned_at )
					document.body.classList.add( 'warning' );
				else
					document.body.classList.add( 'success' );

				setShowTicket( true );
			} )
			.catch( ( error ) => handleBadTicket( ) );
		}
	}, [ ticketNumber ] );

	useEffect( ( ) => {
		if ( '' !== attendeeName )
		{
			const scanner = JSON.parse( localStorage.getItem( 'scanner' ) );

			fetch( 
				window.__rest_uri+'/v2/tickets?event_id='+scanner.event_id+'&q='+attendeeName, 
				{ headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
			)
			.then( ( data ) => data.json( ) )
			.then( function( data ) {
				if ( data.hasOwnProperty( 'ExceptionName' ) && -1 !== data.ExceptionName.indexOf( 'NoAuthenticatedSessionPresent' ) )
				{
					props.handleNoAuth( );
					return;
				}

				if ( 'object' !== typeof data || 0 === data.length )
				{
					handleBadTicket( );
					return;
				}

				setTickets( data );
				setShowAttendees( true );
			} )
			.catch( ( error ) => handleBadTicket( ) );
		}
	}, [ attendeeName ] );

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				{ !showAttendees && !showTicket && <Box
					sx={{
						marginTop: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5" sx={{ mb: 2 }}>
						Lookup Ticket
					</Typography>
					{ /* <Box>
						<QRCodeScanner ticketLookup={handleTicketScan} />
					</Box> */ }
					<Box component="form" onSubmit={handleLookupAttendeeSubmit} noValidate>
						<TextField
							margin="normal"
							required
							fullWidth
							id="attendeeName"
							label="Attendee"
							name="attendeeName"
							autoComplete="new-password"
							size="large"
							autoFocus
						/>
						<Button type="submit" margin="normal" size="large" variant="contained" fullWidth sx={{ mb: 2, color: '#fff' }} >
							LOOK UP
						</Button>
					</Box>
				</Box> }
				{ showTicket && <Box
					sx={{
						marginTop: 4,
						marginBotton: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<ConfirmationNumberIcon />
					</Avatar>
					<Typography component="h3" variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
					{ ticket.event.name }
					</Typography>
					<Typography component="h3" variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
					{ ticket.tickettype.label }
					</Typography>
					<Typography component="h1" variant="h5" sx={{ mt: 2, mb: 2 }}>
						TICKET #{ ticket.id.toString( ).padStart( 8, '0' ) }
					</Typography>
					<Typography component="body1" variant="h6">
					{ ( null !== ticket.person.title && "" !== ticket.person.title ) && ticket.person.title+' ' }{ ticket.person.first_name } { ticket.person.last_name }
					</Typography>
					<Typography component="body1" variant="h6">
					{ ticket.table_number && `Table ${ticket.table_number}` }
					{ ( ticket.table_number && ticket.seat_number ) && " | " }
					{ ticket.seat_number && `Seat ${ticket.seat_number}` }
					</Typography>
					<Box component="form" onSubmit={handleTicketCheckinSubmit} noValidate>
						<TextField
							margin="normal"
							fullWidth
							id="photoFileName"
							label="Photo File Name"
							name="photo_file_name"
							value={ticket.attributes.photo_file_name}
							onChange={handleChangeAttribute}
							autoComplete="new-password"
							autoFocus={ticket.scanned_at == null}
						/>
						<Button type="submit" margin="normal" variant="contained" fullWidth sx={{ mb: 2, color: '#fff' }} >
							Check In
						</Button>
					</Box>
				</Box> }
				{ showAttendees && <Box component="form" onSubmit={handleMultipleTicketCheckinSubmit} noValidate>
					<Box
						sx={{
							marginTop: 4,
							marginBotton: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<ConfirmationNumberIcon />
						</Avatar>
						<Typography component="h3" variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
						{ tickets[0].event.name }
						</Typography>
					</Box>
					<hr />
					{ tickets.map( ticket => ( 
						<Box
							sx={{
								marginTop: 4,
								marginBotton: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Checkbox
								checked={checkins[ticket.id]}
								onClick={handleCheckin(ticket.id)}
								size="large" />
							<Typography component="body1" variant="h6">
							{ ( null !== ticket.person.title && "" !== ticket.person.title ) && ticket.person.title+' ' }{ ticket.person.first_name } { ticket.person.last_name }
							</Typography>
							<Typography component="body1" variant="h6">
							{ ticket.table_number && `Table ${ticket.table_number}` }
							{ null !== ticket.scanned_at && ' | CHECKED IN' }
							</Typography>
							<hr />
						</Box>
					) ) }
					<Button type="submit" margin="normal" variant="contained" size="large" fullWidth sx={{ mb: 2, color: '#fff' }} >
						CHECK IN
					</Button>
				</Box> }
			</Container>
		</ThemeProvider>
	);
}

export default ScannerApp;
